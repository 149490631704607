import { defineStore, acceptHMRUpdate } from "pinia";

export const useBuyoutStore = defineStore("buyoutStore", () => {
  const { t } = useI18n();
  const { notify } = useNotification();
  const localePath = useLocalePath();
  const runtimeConfig = useRuntimeConfig();
  const api = useApi();
  const authStore = useAuthStore();
  const partnerStore = usePartnerStore();
  const commonStore = useCommonStore();
  // General
  const userBuyoutCode = ref<string | null>(null);
  const buyoutReferralId = ref<string | null>(null);
  const buyoutReferralLinkId = ref<string | null>(null);
  const appraisalBuyoutStep = ref<number>(0);
  const mainBuyoutStep = ref<number>(0);
  const personalDataStep = ref<number>(0);
  const takeStep = ref<boolean>(true);
  const newDeviceErrors = ref({ variant: "", color: "" });
  const buyoutPersonalData = ref<PersonalDataInfo>({
    personal_number: "",
    id_number: "",
    id_issued_by: "",
    id_valid_to: "",
    degree_before_name: "",
    first_name: "",
    last_name: "",
    gender: null,
    street_and_number: "",
    city: "",
    country: null,
    postal_code: "",
    phone_number: {
      prefix: `+${commonStore.selectedCountry.iso2}`,
      suffix: ""
    },
    pickup_address: null,
    nationality: null,
    place_of_birth: "",
    email: "",
    bank_code: "",
    bank_account_number: "",
    iban: "",
    language: ""
  });
  const accountAlreadyExists = ref<boolean>(false);
  const buyoutPersonalDataErrors = ref<Record<string, string[]>>({});
  const pickUpAddress = ref<PickUpAddress>({
    street_and_number: "",
    city: "",
    postal_code: "",
    country: null,
    was_address_confirmed: null
  });
  const pickUpAddressErrors = ref<Record<string, string[]>>({});
  const device = ref<BuyoutDetailDevice | null >(null);
  const newDevice = ref<BuyoutDetailDevice | null >(null);
  const stateOfDevice = ref<StateOfDevice | null >(null);
  const buyoutDetail = ref<BuyoutDetail | null>(null);
  const bankAccountNumber = ref<string>("");
  const clientEmail = ref<string>("");
  const defects = ref<BuyoutDetailDefect[] | null>(null);
  const buyoutLoadedFromUrl = ref<boolean>(false);
  // Available
  const availableManufacturers = ref<BuyoutDeviceManufacturer[]>([]);
  const availableProductVariants = ref<BuyoutDeviceVariant | null>(null);
  const availableProductColors = ref<BuyoutProductColor[]>([]);
  const availableDeviceQualities = ref<BuyoutProductQuality[]>([]);
  const availableNewMasters = ref<MasterProduct[]>([]);
  const availableDeviceTypes = ref<BuyoutDeviceType[]>([]);
  const availableMetaMasters = ref<BuyoutMetaMaster[]>([]);
  const availableNewMetaMasters = ref<MixedMetaOther[]>([]);
  const availableOthersNewMetaMasters = ref<MetaMasterBonus[]>([]);
  const selectedOthersNewMetaMasters = ref<MetaMasterBonus | null>(null);
  const availableNewManufacturers = ref<BuyoutDeviceManufacturer[]>([]);
  const selectedNewManufacturer = ref<BuyoutDeviceManufacturer | null>(null);
  const availableNewProductVariants = ref<BuyoutDeviceVariant | null>(null);
  const availableNewProductColors = ref<BuyoutProductColor[]>([]);
  const availableBonusPartners = ref<PartnerBonus[]>([]);
  // Selected
  const selectedDeviceType = ref<BuyoutDeviceType | null>(null);
  const selectedManufacturer = ref<BuyoutDeviceManufacturer | null>(null);
  const selectedMetaMaster = ref<BuyoutMetaMaster | null>(null);
  const selectedProductVariant = ref<BuyoutDeviceAttributeValue | null>(null);
  const selectedProductColor = ref<BuyoutProductColor | null>(null);
  const selectedProductQuality = ref<BuyoutProductQuality | null>(null);
  const buyoutDeviceImei = ref<string | null>(null);
  const selectedNewMetaMaster = ref<MixedMetaOther | null>(null);
  const selectedNewProductVariant = ref<BuyoutDeviceAttributeValue | null>(null);
  const selectedNewProductColor = ref<BuyoutProductColor | null>(null);
  const selectedBuyoutBonuses = ref<Bonus[] | null>(null);
  const defectsAnswers = ref<EnhancedBuyoutDefect[] | []>([]);
  const accessories = ref<Accessory[] | []>([]);
  const selectedChecks = ref<string[]>([]);
  const checks = ref<TypeBuyoutCheck[]>([]);
  const selectedBonusPartner = ref<PartnerBonus | null>(null);
  // Loading
  const newManufacturersLoading = ref<boolean>(false);
  const loadingAvailableDeviceTypes = ref<boolean>(false);
  const loadingManufacturers = ref<boolean>(false);
  const loadingMetaMasters = ref<boolean>(false);
  const loadingAvailableProductVariants = ref<boolean>(false);
  const loadingAvailableProductColors = ref<boolean>(false);
  const loadingAvailableProductAppraisal = ref<boolean>(false);
  const moveToPersonalDataLoading = ref<boolean>(false);
  const defectsLoading = ref<boolean>(false);
  const bonusesLoading = ref<boolean>(false);
  const minorBonusesLoading = ref<boolean>(false);
  const otherBonusesLoading = ref<boolean>(false);
  const buyoutBonusesLoading = ref<boolean>(false);
  const mastersLoading = ref<boolean>(false);
  const accessoriesLoading = ref<boolean>(false);
  const colorLoading = ref<boolean>(false);
  const personalDataLoaded = ref<boolean>(false);
  const additionalUserDataLoaded = ref<boolean>(false);
  const bonusesForSelectedQuality = ref<boolean>(true);
  const hideMetaMasterStep = computed<boolean>(() => {
    return selectedDeviceType?.value !== null && (selectedDeviceType.value.identifier === "foto" || selectedDeviceType.value.identifier === "objektivy");
  });
  const showBonuses = computed<boolean>(() => {
    if (partnerStore.getBuyoutPartner === null) {
      // if there are no partners, available partners, it means there are no active bonuses
      // (1 partner is "Other seller";
      //  if the length is 0 then the partners weren't fetched yet, so we don't make any assumptions)
      return availableBonusPartners.value.length !== 1 && bonusesForSelectedQuality.value;
    } else {
      return partnerStore.getBuyoutPartner.has_buyout_bonuses && bonusesForSelectedQuality.value;
    }
  });

  function getBuyoutCustomerId () {
    // Return user id only if user is not partner ambassador
    return (authStore?.data?.id && !(authStore?.data?.partner?.id)) ? authStore.data.id : null;
  }

  function getBuyoutReferralId () {
    // If user is partner ambassador -> creating buyout for customer
    if (authStore?.data?.partner?.id && authStore?.data?.reference_code) {
      return authStore.data.reference_code;
    }
    // If customer is creating buyout by himself and is via referral code
    if (buyoutReferralId?.value) {
      return buyoutReferralId.value;
    }
    // If buyout is created in premium partner environment -> the least priority
    if (partnerStore?.partner?.reference_code) {
      return partnerStore.partner.reference_code;
    }

    return undefined;
  }

  // Methods
  function resetPersonalDataErrors (key: string) {
    if (buyoutPersonalDataErrors.value) {
      delete buyoutPersonalDataErrors.value[key];
    }
  }
  function resetManufacturer (withDeps: boolean = true) {
    selectedManufacturer.value = null;
    availableManufacturers.value = [];
    if (withDeps) {
      resetMetaMaster(false);
      resetProductVariant(false);
      resetProductColor(false);
      resetSelectedProductQuality();
    }
  }
  function resetMetaMaster (withDeps: boolean = true) {
    selectedMetaMaster.value = null;
    availableMetaMasters.value = [];
    if (withDeps) {
      resetProductVariant(false);
      resetProductColor(false);
      resetSelectedProductQuality();
    }
  }
  function resetProductVariant (withDeps: boolean = true) {
    selectedProductVariant.value = null;
    availableProductVariants.value = null;
    checks.value = [];
    selectedChecks.value = [];
    defectsAnswers.value = [];
    accessories.value = [];
    if (withDeps) {
      resetProductColor(false);
      resetSelectedProductQuality();
    }
  }
  function resetProductColor (withDeps: boolean = true) {
    selectedProductColor.value = null;
    availableProductColors.value = [];
    if (withDeps) {
      resetSelectedProductQuality();
    }
  }
  function resetSelectedProductQuality () {
    selectedProductQuality.value = null;
    availableDeviceQualities.value = [];
  }
  async function changeURL () {
    await nextTick();
    const params = {
      type: selectedDeviceType.value?.seo_name,
      manufacturer: selectedManufacturer.value?.seo_name,
      meta_master: selectedMetaMaster.value?.seo_name,
      variant: selectedProductVariant.value?.master_product?.seo_name,
      color: selectedProductColor.value?.seo_name,
      quality: selectedProductQuality.value?.quality.short_name
    };
    const filledUrlParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value)); // eslint-disable-line no-unused-vars

    let url = localePath({ name: "buyout-type-manufacturer-meta_master-variant-color-quality", params: filledUrlParams, query: { refId: partnerStore?.partner?.reference_code } });

    if (buyoutReferralId?.value) {
      url += `?refId=${buyoutReferralId.value}`;
    }

    window.history.replaceState({}, "", `/${runtimeConfig.public.region}${url}`);
  }

  async function loadAvailableDeviceTypes () {
    loadingAvailableDeviceTypes.value = true;
    const { data, error } = await api.call<BuyoutDeviceType[]>(
      api.commonUrls.product.DEVICE_TYPES_FOR_BUYOUT(),
      { query: partnerStore.getBuyoutPartner ? { partner_id: partnerStore.getBuyoutPartner.id } : {} }
    );
    if (data.value) {
      availableDeviceTypes.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingAvailableDeviceTypes.value = false;
  }

  async function loadAvailableManufacturers () {
    if (!selectedDeviceType.value) { return; }
    loadingManufacturers.value = true;
    const { data, error } = await api.call<BuyoutDeviceManufacturer[]>(api.commonUrls.product.MANUFACTURERS_BY_CATEGORY(selectedDeviceType.value?.identifier));
    if (data.value) {
      availableManufacturers.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingManufacturers.value = false;
  }

  async function loadAvailableMetaMasters () {
    if (!selectedDeviceType.value || !selectedManufacturer.value) { return; }
    loadingMetaMasters.value = true;
    const { data, error } = await api.call<BuyoutMetaMaster[]>(api.commonUrls.product.META_MASTER_BY_CATEGORY_BY_MANUFACTURER(selectedDeviceType.value?.identifier, selectedManufacturer?.value?.seo_name));
    if (data.value) {
      availableMetaMasters.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingMetaMasters.value = false;
  }

  async function loadAvailableProductVariants () {
    if (!selectedMetaMaster.value) { return; }
    loadingAvailableProductVariants.value = true;
    const { data, error } = await api.call<BuyoutDeviceVariant>(api.commonUrls.product.ATTR_CHOICES_BY_META_MASTER(selectedMetaMaster.value?.id));
    if (data.value) {
      availableProductVariants.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingAvailableProductVariants.value = false;
  }

  async function loadAvailableProductColors () {
    loadingAvailableProductColors.value = true;
    if (!selectedProductVariant.value) { return; }
    const { data, error } = await api.call<BuyoutProductColor[]>(api.commonUrls.product.COLOR_CHOICES_BY_MASTER_DEVICE(selectedProductVariant.value.master_product.id));
    if (data.value) {
      availableProductColors.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingAvailableProductColors.value = false;
  }

  async function loadAvailableProductAppraisal () {
    if (!selectedProductColor.value || !selectedProductVariant.value) { return; }
    loadingAvailableProductAppraisal.value = true;
    const { data, error } = await api.call<BuyoutProductQuality[]>(api.commonUrls.product.QUALITY_CHOICES_BY_COLOR_AND_MASTER(selectedProductVariant.value.master_product.id, selectedProductColor.value.color.id));
    if (data.value) {
      availableDeviceQualities.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    loadingAvailableProductAppraisal.value = false;
  }

  async function loadAvailableBonusPartners (preparedData: PartnerBonus[] | null = null) {
    if (!selectedProductQuality!.value) { return; }
    bonusesLoading.value = true;
    let bonusData = preparedData;
    if (!preparedData && userBuyoutCode?.value) {
      const { data } = await api.call<PartnerBonus[]>(api.commonUrls.buyout.NEW_DEVICES_PARTNERS_LIST(userBuyoutCode!.value));
      if (data) { bonusData = data.value; }
    }

    if (bonusData) {
      availableBonusPartners.value = bonusData;
      availableBonusPartners.value.push({
        id: "00000000-0000-0000-0000-000000000000",
        fe_name: t("buyout.common.other_seller"),
        name: t("buyout.common.other_seller"),
        logo: "",
        seo_name: "other",
        max_bonus_value: 0,
        more_bonus_values_available: false
      });
    } else {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    bonusesLoading.value = false;
  }

  async function loadAvailableNewMetaMasters () {
    availableNewMetaMasters.value = [];
    selectedNewMetaMaster.value = null;
    availableNewProductColors.value = [];
    selectedBuyoutBonuses.value = [];
    selectedNewProductVariant.value = null;
    selectedNewProductColor.value = null;
    availableNewProductVariants.value = null;
    if (!selectedBonusPartner?.value?.seo_name || selectedBonusPartner.value!.seo_name === "other") {
      return;
    }
    if (partnerStore.getBuyoutPartner) {
      bonusesLoading.value = true;
    } else {
      minorBonusesLoading.value = true;
    }
    const { data, error } = await api.call<MetaMastersWithOthers>(api.commonUrls.buyout.NEW_DEVICES_BONUS_LIST_BY_PARTNER(userBuyoutCode!.value, selectedBonusPartner!.value!.id));
    if (data.value) {
      // @ts-ignore
      const masters = data.value.meta_master_bonuses.map((mm) => {
        return { ...mm, is_other: false };
      });

      const others = data.value.other_bonuses.map((ob) => {
        return { ...ob, is_other: true, image: "" };
      });

      availableNewMetaMasters.value = masters.concat(others);
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    bonusesLoading.value = false;
    minorBonusesLoading.value = false;
  }

  async function loadAvailableNewManufacturers () {
    availableNewManufacturers.value = [];
    selectedNewManufacturer.value = null;
    availableNewProductColors.value = [];
    selectedBuyoutBonuses.value = [];
    selectedNewProductVariant.value = null;
    selectedNewProductColor.value = null;
    availableNewProductVariants.value = null;
    newManufacturersLoading.value = true;
    const {
      data,
      error
    } = await api.call<BuyoutDeviceManufacturer[]>(api.commonUrls.buyout.NEW_DEVICES_OTHERS_MANUFACTURERS(userBuyoutCode!.value, selectedBonusPartner!.value!.id, selectedNewMetaMaster!.value!.id));
    if (data.value) {
      availableNewManufacturers.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }

    newManufacturersLoading.value = false;
  }

  async function loadAvailableOthersMetaMasters () {
    selectedOthersNewMetaMasters.value = null;
    availableNewProductColors.value = [];
    selectedBuyoutBonuses.value = [];
    selectedNewProductVariant.value = null;
    selectedNewProductColor.value = null;
    availableNewProductVariants.value = null;
    if (!selectedNewManufacturer?.value) {
      // new manufacturer unselected
      availableNewProductVariants.value = null;
      selectedNewProductColor.value = null;
      return;
    }

    minorBonusesLoading.value = true;

    const { data, error } = await api.call<MetaMasterBonus[]>(api.commonUrls.buyout.NEW_DEVICES_OTHERS_BONUS_LIST_BY_PARTNER_BY_MANUFACTURER_BY_BONUS(userBuyoutCode!.value, selectedBonusPartner!.value!.id, selectedNewManufacturer!.value!.id, selectedNewMetaMaster.value!.id));
    if (data.value) {
      availableOthersNewMetaMasters.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }

    bonusesLoading.value = false;
    minorBonusesLoading.value = false;
  }

  async function loadAvailableNewMasters (isOther: boolean = false) {
    availableNewProductColors.value = [];
    selectedBuyoutBonuses.value = [];
    selectedNewProductVariant.value = null;
    if (!selectedNewMetaMaster?.value && !isOther) {
      // metamaster unselected
      availableNewProductVariants.value = null;
      selectedNewProductColor.value = null;
      return;
    }
    if (!selectedOthersNewMetaMasters?.value && isOther) {
      // metamaster unselected
      availableNewProductVariants.value = null;
      selectedNewProductColor.value = null;
      return;
    }
    if (isOther) {
      otherBonusesLoading.value = true;
    } else {
      mastersLoading.value = true;
    }
    const {
      data,
      error
    } = await api.call<BuyoutDeviceVariant>(api.commonUrls.product.ATTR_CHOICES_FOR_NEW_BY_META_MASTER(isOther ? selectedOthersNewMetaMasters!.value!.id : selectedNewMetaMaster!.value!.id, selectedBonusPartner!.value!.id, userBuyoutCode!.value, Number(isOther)));

    if (data.value) {
      availableNewProductVariants.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    mastersLoading.value = false;
    otherBonusesLoading.value = false;
  }

  async function loadAvailableNewColors () {
    colorLoading.value = true;
    selectedNewProductColor.value = null;
    const { data, error } = await api.call<BuyoutProductColor[]>(api.commonUrls.product.COLOR_CHOICES_BY_MASTER_DEVICE_BY_PARTNER_NEW(selectedNewProductVariant!.value!.master_product!.id, selectedBonusPartner.value!.id));
    if (data.value) {
      availableNewProductColors.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    colorLoading.value = false;
  }

  async function loadDefects () {
    defectsLoading.value = true;
    if (!selectedProductColor.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.no_data"),
        type: "error"
      });
      defectsLoading.value = false;
      return;
    }
    const { data, error } = await api.call<BuyoutDefect[]>(api.commonUrls.buyout.DEFECTS_BY_DEVICE(selectedProductColor.value!.id));
    if (data.value) {
      defectsAnswers.value = data.value.map((answer: BuyoutDefect) => ({
        ...answer,
        selectedValue: null
      }));
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    defectsLoading.value = false;
  }

  async function loadAccessories () {
    accessoriesLoading.value = true;
    if (!selectedProductColor.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.no_data"),
        type: "error"
      });
      accessoriesLoading.value = false;
      return;
    }
    const { data, error } = await api.call<Accessory[]>(api.commonUrls.buyout.ACCESSORIES_BY_MASTER_DEVICE(selectedProductVariant!.value!.master_product!.id));
    if (data.value) {
      accessories.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        type: "error"
      });
    }
    accessoriesLoading.value = false;
  }

  async function loadBuyoutBonuses () {
    buyoutBonusesLoading.value = true;
    if (!userBuyoutCode.value) {
      return;
    }
    const { data, error } = await api.call<Bonus[]>(api.commonUrls.buyout.BONUS_BY_OLD_NEW_BY_PARTNER(userBuyoutCode!.value, selectedNewProductVariant!.value!.master_product!.id, selectedBonusPartner!.value!.id));
    if (data.value) {
      selectedBuyoutBonuses.value = data.value;
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    buyoutBonusesLoading.value = false;
  }

  function validateNewDevice () {
    if (selectedBonusPartner?.value && selectedNewMetaMaster?.value) {
      if (!selectedNewProductVariant?.value) {
        newDeviceErrors.value.variant = t("form.validation.required");
        return false;
      }
      if (!selectedNewProductColor?.value) {
        newDeviceErrors.value.color = t("form.validation.required");
        return false;
      }
    }

    return true;
  }

  async function moveToPersonalData () {
    moveToPersonalDataLoading.value = true;
    const { data, error } = await api.call<string>(api.commonUrls.buyout.CREATE_BONUS_NEW_DEVICE(), {
      method: "PUT",
      body: {
        new_device_id: selectedNewProductColor?.value?.id ? selectedNewProductColor!.value.id : null,
        bonuses_ids: selectedBuyoutBonuses?.value && selectedBuyoutBonuses?.value?.length > 0 ? selectedBuyoutBonuses?.value.map(bonus => bonus.id) : [],
        registration_number: userBuyoutCode!.value,
        new_device_partner_id: selectedBonusPartner?.value && selectedNewProductColor?.value ? selectedBonusPartner!.value!.id : null,
        customer_id: getBuyoutCustomerId()
      }
    });
    if (data.value) {
      if (takeStep.value) {
        mainBuyoutStep.value += 1;
      }
    }
    if (error.value) {
      notify({
        title: t("form.common.error"),
        text: t("general.error_sending_request"),
        type: "error"
      });
    }
    moveToPersonalDataLoading.value = false;
  }

  function resetBuyoutProcess () {
    resetManufacturer();
    bonusesForSelectedQuality.value = true;
    selectedNewMetaMaster.value = null;
    selectedBuyoutBonuses.value = [];
    selectedBonusPartner.value = null;
    selectedNewProductVariant.value = null;
    selectedNewProductColor.value = null;
    availableNewProductVariants.value = null;
    availableNewProductColors.value = [];
    availableNewMetaMasters.value = [];
    availableNewMasters.value = [];
    appraisalBuyoutStep.value = 0;
    mainBuyoutStep.value = 0;
    personalDataStep.value = 0;
    userBuyoutCode.value = null;
    buyoutDeviceImei.value = null;
    personalDataLoaded.value = false;
    additionalUserDataLoaded.value = false;
    buyoutLoadedFromUrl.value = false;
    availableBonusPartners.value = [];
    buyoutPersonalData.value = {
      personal_number: "",
      id_number: "",
      id_issued_by: "",
      id_valid_to: "",
      degree_before_name: "",
      first_name: "",
      last_name: "",
      gender: null,
      street_and_number: "",
      city: "",
      country: null,
      postal_code: "",
      phone_number: {
        prefix: "+420",
        suffix: ""
      },
      pickup_address: null,
      nationality: null,
      place_of_birth: "",
      email: "",
      bank_code: "",
      bank_account_number: "",
      iban: "",
      language: ""
    };
    buyoutDetail.value = null;
    accountAlreadyExists.value = false;
    pickUpAddress.value = {
      street_and_number: "",
      city: "",
      postal_code: "",
      country: null,
      was_address_confirmed: null
    };
  }

  function resetSelectedDevices () {
    selectedNewMetaMaster.value = null;
    selectedBuyoutBonuses.value = [];
    selectedNewProductVariant.value = null;
    selectedNewProductColor.value = null;
  }

  async function selectProductVariant (productVariant: BuyoutDeviceAttributeValue) {
    if (selectedProductVariant?.value?.master_product.id !== productVariant.master_product.id) {
      selectedProductVariant.value = productVariant;
      resetProductColor();
      await loadAvailableProductColors();
      await changeURL();
    }
  }

  async function selectProductColor (productColor: BuyoutProductColor) {
    if (selectedProductColor.value !== productColor) {
      selectedProductColor.value = productColor;
      resetSelectedProductQuality();
      await changeURL();
    }
  }

  return {
    buyoutDetail,
    device,
    newDevice,
    stateOfDevice,
    userBuyoutCode,
    buyoutReferralId,
    buyoutReferralLinkId,
    mainBuyoutStep,
    appraisalBuyoutStep,
    personalDataStep,
    availableProductVariants,
    availableProductColors,
    availableManufacturers,
    availableDeviceQualities,
    availableNewMasters,
    availableDeviceTypes,
    availableMetaMasters,
    selectedDeviceType,
    selectedManufacturer,
    selectedMetaMaster,
    selectedProductVariant,
    selectedProductColor,
    selectedProductQuality,
    buyoutDeviceImei,
    buyoutPersonalData,
    buyoutPersonalDataErrors,
    pickUpAddress,
    newDeviceErrors,
    loadingManufacturers,
    selectedNewMetaMaster,
    selectedNewProductVariant,
    selectedNewProductColor,
    selectedBuyoutBonuses,
    pickUpAddressErrors,
    defectsAnswers,
    checks,
    selectedChecks,
    defectsLoading,
    bonusesLoading,
    mastersLoading,
    accessoriesLoading,
    colorLoading,
    clientEmail,
    loadingAvailableDeviceTypes,
    loadingMetaMasters,
    loadingAvailableProductVariants,
    loadingAvailableProductColors,
    loadingAvailableProductAppraisal,
    availableNewMetaMasters,
    availableNewProductVariants,
    newManufacturersLoading,
    availableNewProductColors,
    showBonuses,
    personalDataLoaded,
    additionalUserDataLoaded,
    accessories,
    buyoutLoadedFromUrl,
    availableBonusPartners,
    selectedBonusPartner,
    minorBonusesLoading,
    hideMetaMasterStep,
    availableNewManufacturers,
    selectedNewManufacturer,
    selectProductVariant,
    selectProductColor,
    getBuyoutCustomerId,
    getBuyoutReferralId,
    resetManufacturer,
    resetMetaMaster,
    resetProductVariant,
    resetProductColor,
    resetSelectedProductQuality,
    changeURL,
    loadAvailableDeviceTypes,
    loadAvailableManufacturers,
    loadAvailableMetaMasters,
    loadAvailableProductVariants,
    loadAvailableProductColors,
    loadAvailableProductAppraisal,
    resetPersonalDataErrors,
    resetBuyoutProcess,
    loadDefects,
    loadAvailableNewMasters,
    loadAvailableNewColors,
    loadAvailableNewMetaMasters,
    loadAvailableBonusPartners,
    loadAvailableNewManufacturers,
    validateNewDevice,
    loadAccessories,
    moveToPersonalDataLoading,
    bankAccountNumber,
    takeStep,
    loadBuyoutBonuses,
    moveToPersonalData,
    resetSelectedDevices,
    defects,
    accountAlreadyExists,
    bonusesForSelectedQuality,
    buyoutBonusesLoading,
    loadAvailableOthersMetaMasters,
    selectedOthersNewMetaMasters,
    availableOthersNewMetaMasters,
    otherBonusesLoading
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBuyoutStore, import.meta.hot));
}
